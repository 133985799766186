console.log('Vite ⚡️ Rails')
// Example: Load Rails libraries in Vite.
import * as Turbo from '@hotwired/turbo'
// serve per il menu hamburger (magari avendo tempo si può importare solo quello)
import * as bootstrap from 'bootstrap'

Turbo.start()
import '/application.sass'

  document.addEventListener("turbo:load", manage_closing_commission)
  document.addEventListener("turbo:frame-load", manage_closing_commission)

  function manage_closing_commission() {
    const buttons = document.getElementsByClassName("btn_to_change_commission_status")
    Array.from(buttons).forEach(button => {
      button.addEventListener("click", function(e) {
        const form = this.closest('form');
        var newStatus = this.getAttribute('data-new-status');

        if (newStatus === "closed") {
          e.preventDefault();
          const modalId = this.getAttribute('data-modal-id');
          const modal = new bootstrap.Modal(document.getElementById(modalId));
          modal.show()

        }
      })
    })

  }

  // TODO: questo codice va in un controller stimulus
  document.addEventListener("turbo:frame-load", manageImagesPreviews)

  function manageImagesPreviews() {
    const imgForms = document.getElementsByClassName('img-component-form')
    console.log(imgForms)
    Array.from(imgForms).forEach((form) => {
      manageImagePreview(form)
    });
  }

  function manageImagePreview(form) {
    const imageInput = form.getElementsByClassName('component-image-input')[0];
    let imagePreview = form.getElementsByClassName('img-component-preview-persisted')[0];
    if (!imagePreview) {
      imagePreview = form.getElementsByClassName('img-component-preview-new')[0];
    }
    imageInput.addEventListener('change', function() {
      const file = this.files[0];

      console.log(file)
      const urlForSource = URL.createObjectURL(file)
      imagePreview.getElementsByTagName('img')[0].src = urlForSource
      if (imagePreview.classList.contains('d-none')) {
        imagePreview.classList.remove('d-none');
      }

    });
  }